@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

html,
body,
main,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.animatedClipPathLoader {
  transition: -webkit-clip-path 1500ms;
  transition: clip-path 1500ms;
  transition: clip-path 1500ms, -webkit-clip-path 1500ms;
  -webkit-clip-path: inset(0px var(--maskX) 0px 0px);
          clip-path: inset(0px var(--maskX) 0px 0px);
}

body::-webkit-scrollbar {
  display: none;
}

#container {
  width: 200px;
  height: 200px;
}

@-webkit-keyframes animation {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}

@keyframes animation {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}

#spinner {
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-name: animation;
          animation-name: animation;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-timing-function: cubic-bezier;
          animation-timing-function: cubic-bezier;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.dyn-gradient {
  background: linear-gradient(45deg, #00a8ff, #4292fb, #ff3496, #00a8ff);
  background-size: 400% 800%;
  -webkit-animation: gradient 30s ease infinite;
          animation: gradient 30s ease infinite;
}

body {
  padding: 0;
  margin: 0;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.floater {
  padding: 9vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  position: fixed;
  top: 0;
  width: 100%;
  background: linear-gradient(45deg, #00a8ff, #4292fb, #ff3496, #00a8ff);
  background-size: 400% 800%;
  -webkit-animation: gradient 30s ease infinite;
          animation: gradient 30s ease infinite;
}

.logo {
  height: 10vw;
}

.content {
  padding-top: 12vw;
  padding-left: 10vw;
  padding-right: 10vw;
}

li,
p,
h1,
h2 {
  font-family: 'Roboto';
  font-weight: 500;
  color: rgb(32, 32, 32);
}

li,
p {
  font-weight: 300;
  font-size: 2vh;
}

h2 {
  font-size: 2.5vh;
}

h1 {
  font-weight: 700;
  font-size: 5vh;
}

@media (min-width: 991.98px) {
  .content {
    padding-top: 8vw;
    padding-left: 20vw;
    padding-right: 20vw;
    padding-bottom: 10vw;
  }
  .logo {
    height: 5vw;
  }
  .floater {
    padding: 19vw;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
  }
  h1 {
    font-weight: 700;
    font-size: 4vh;
  }
}

a:link {
  color: rgb(27, 27, 255);
  text-decoration: underline;
}

a:visited {
  color: rgb(27, 27, 255);
  text-decoration: underline;
}

a:hover {
  color: rgb(255, 235, 147);
  text-decoration: underline;
}

a:active {
  color: yellow;
  text-decoration: underline;
}

.logoHome {
  width: 50vw;
}

.logoCont {
  text-align: center;
  padding-top: 20vh;
}

.soon {
  font-family: 'Roboto';
  text-align: center;
  font-weight: 500;
  font-size: 3vw;
  color: rgba(255, 255, 255, 0.771);
}

.homeBody {
  background: linear-gradient(45deg, #00a8ff, #4292fb, #ff3496, #00a8ff);
  background-size: 400% 800%;
  -webkit-animation: gradient 30s ease infinite;
          animation: gradient 30s ease infinite;
}

